// extracted by mini-css-extract-plugin
export var mvpAppDevBenefitsSection = "D_fq";
export var mvpAppDevCaseSection = "D_fy";
export var mvpAppDevChoiceSection = "D_fw";
export var mvpAppDevClientQuotes = "D_fz";
export var mvpAppDevCostSection = "D_ft";
export var mvpAppDevExpertiseSection = "D_fx";
export var mvpAppDevIndustriesSection = "D_fn";
export var mvpAppDevPracticesSection = "D_fv";
export var mvpAppDevPrimeSection = "D_fl";
export var mvpAppDevProcessSection = "D_fr";
export var mvpAppDevServicesSection = "D_fm";
export var mvpAppDevTechSection = "D_fs";
export var mvpAppDevTypesSection = "D_fp";